<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-spinner :load="fetching"/>
    <div v-show="!fetching">
      <!-- Barra de Búsqueda -->
      <searchingBar :components="['filter']" @Fectch="FetchTable" @filterValue="filterValue = $event"/>
      <!--Botonera  -->
      <div class="row justify-content-center mb-4">
        <div class="col-12 text-right">
          <b-button @click="OpenModal()" pill  variant="primary" >
            <b-icon-plus/> Nuevo
          </b-button>
        </div>
      </div>
      <!--MODAL-->
      <b-modal  ref="modalForm" size="xl" @hide="CloseModal">
        <a-form-model ref="formRes" layout="horizontal" :model="formObj.form" :rules="formRules">
          <!--TEXT - Nombre del Proceso-->
          <a-form-model-item label="Nombre del Proceso" ref="name"  prop="name">
            <a-input v-model="formObj.form.name" placeholder="Nombre del Proceso" size="large"/>
          </a-form-model-item>
          <!--TEXT -Nombre del Proceso -->
          <!-- Botones -->
          <my-spinner :load="spinner"/>
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="onSubmit">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseModal"/>
        </template>
      </b-modal>
      <!--MODAL-->
      <!--TABLA-->
      <a-table bordered :scroll="{x:1300}" :columns="tableObj.columns"
          :data-source="tableObj.data"
          :pagination="tableObj.pagination"
          :loading="tableObj.loading"
          @change="FetchTable"
          :row-key="record => record.id">
          <template slot="actions" slot-scope="row">
            <b-button pill variant="secondary" @click="OpenModal(row)">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/> Editar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/>
            </div>
            </b-button>
            <b-button pill variant="danger" @click="onDelete(row.id)">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
              <b-icon icon="trash"/> Eliminar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
              <b-icon icon="trash"/>
            </div>
            </b-button>
          </template>
      </a-table>
      <!--TABLA-->
    </div>
  </div>
</template>

<script>
import { notification, Modal } from 'ant-design-vue'
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
const formDefault = { name: '' }
export default {
  name: 'processTypes',
  mixins: [apiV2Mixin],
  data() {
    return {
      // <!-- Constantes -->
      resourceName: 'Tipos de Proceso',
      // <!-- Table -->
      tableObj: {
        data: [],
        columns: [
          {
            title: 'Nombre del Proceso',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center',
            key: 'x',
            scopedSlots: { customRender: 'actions' },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      filterValue: '',
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // form
      defaultForm: JSON.stringify(formDefault),
      formObj: {
        resourceType: 'processTypes',
        form: {},
      },
      formRules: {
        name: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      spinner: false,
      fetching: false,
    }
  },
  methods: {
    async FetchTable(pagE) {
      const params = {
        orderType: 'asc',
        orderField: 'name',
      }
      if (this.filterValue) params.search = this.filterValue
      await this.GetTableResourcev2(pagE, 'processTypes', this.tableObj, params)
    },
    OpenModal(data = {}) {
      this.spinner = false
      this.formObj.form = JSON.parse(this.defaultForm)
      if (Object.keys(data).length > 0) {
        this.formObj.resourceType = `processTypes/${data.id}`
        this.formObj.form.name = data.name
      }
      this.$refs.modalForm.show()
    },
    CloseModal() {
      this.spinner = false
      this.formObj.form = JSON.parse(this.defaultForm)
      this.formObj.resourceType = 'processTypes'
      this.$refs.modalForm.hide()
    },
    async onSubmit() {
      this.$refs.formRes.validate(async(valid) => {
        if (valid) {
          if (this.formObj.resourceType !== 'processTypes') {
            // put
            await this.PutResourcev2(this.formObj, async () => {
              await this.FetchTable()
              this.CloseModal()
            })
          } else {
            // post
            await this.PostResourcev2(this.formObj, async () => {
              await this.FetchTable()
              this.CloseModal()
            })
          }
        } else return false
      })
    },
    async onDelete(id) {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk() {
          me.fetching = true
          const url = `processTypes/${id}`
          await me.AxiosDelete(url, {}, async () => {
            await me.FetchTable()
            me.fetching = false
          })
          me.fetching = false
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>
