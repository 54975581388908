<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-spinner :load="fetching"/>
    <div v-show="!fetching">
      <searchingBar :components="['filter']" @Fectch="FetchTable" @filterValue="filterValue = $event"/>
      <div class="row justify-content-center mb-4">
        <div class="col-12 text-right">
          <b-button @click="OpenModal()" pill  variant="primary" >
            <b-icon-plus/> Nuevo
          </b-button>
        </div>
      </div>
      <b-modal  ref="modalForm" size="xl" @hide="CloseModal">
        <a-form-model ref="formRes" layout="horizontal" :model="formObj.form" :rules="formRules">
          <a-form-model-item label="Razón de Paro" ref="description"  prop="description">
            <a-input v-model="formObj.form.description" placeholder="Razón de paro" size="large"/>
          </a-form-model-item>
          <a-form-model-item label="Planta" ref="work_center_id"  prop="work_center_id" >
            <a-select v-model="formObj.form.work_center_id" allowClear
                    placeholder="Seleccionar Planta" show-search>
              <a-select-option  v-for="option in workCenters" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <my-spinner :load="spinner"/>
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="onSubmit">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseModal"/>
        </template>
      </b-modal>
      <a-table bordered :scroll="{x:1300}" :columns="tableObj.columns"
          :data-source="tableObj.data"
          :pagination="tableObj.pagination"
          :loading="tableObj.loading"
          @change="FetchTable"
          :row-key="record => record.id">
          <template slot="workcenter" slot-scope="row">
            <div>
              {{ row.work_centers.code }} - {{ row.work_centers.name }}
            </div>
          </template>
          <template slot="actions" slot-scope="row">
            <b-button pill variant="secondary" @click="OpenModal(row)">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/> Editar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/>
            </div>
            </b-button>
            <b-button pill variant="danger" @click="onDelete(row.id)">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
              <b-icon icon="trash"/> Eliminar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
              <b-icon icon="trash"/>
            </div>
            </b-button>
          </template>
      </a-table>
      <!--TABLA-->
    </div>
  </div>
</template>

<script>
import { notification, Modal } from 'ant-design-vue'
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
const formDefault = { description: '', work_center_id: undefined }
export default {
  name: 'stopReasons',
  mixins: [apiV2Mixin],
  data() {
    return {
      workCenters: [],
      resourceName: 'Tipos de Razones de Paro',
      tableObj: {
        data: [],
        columns: [
          {
            title: 'Razón de Paro',
            dataIndex: 'description',
            key: 'name',
          },
          {
            title: 'Planta',
            dataIndex: '',
            key: 'work_centers',
            scopedSlots: { customRender: 'workcenter' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center',
            key: 'x',
            scopedSlots: { customRender: 'actions' },
            width: '14%',
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      filterValue: '',
      defaultForm: JSON.stringify(formDefault),
      formObj: {
        resourceType: 'operationStopReasons',
        form: {
          description: '',
          work_center_id: undefined,
        },
      },
      formRules: {
        description: [{ required: true, message: 'La descripción es un campo obligatorio.', trigger: 'change' }],
        work_center_id: [{ required: true, message: 'La planta es un campo obligatorio.', trigger: 'change' }],
      },
      spinner: false,
      fetching: false,
    }
  },
  methods: {
    async FetchTable(pagE) {
      const params = { }
      if (this.filterValue) params.search = this.filterValue
      await this.GetTableResourcev2(pagE, 'operationStopReasons', this.tableObj, params)
    },
    OpenModal(data = {}) {
      this.spinner = false
      this.formObj.form = JSON.parse(this.defaultForm)
      if (Object.keys(data).length > 0) {
        this.formObj.resourceType = `operationStopReasons/${data.id}`
        this.formObj.form.description = data.description
        this.formObj.form.work_center_id = parseInt(data.work_center_id)
      }
      console.log('Form:', this.formObj.form)
      this.$refs.modalForm.show()
    },
    CloseModal() {
      this.spinner = false
      this.formObj.form = JSON.parse(this.defaultForm)
      this.formObj.resourceType = 'operationStopReasons'
      this.$refs.modalForm.hide()
    },
    async onSubmit() {
      this.$refs.formRes.validate(async(valid) => {
        if (valid) {
          if (this.formObj.resourceType !== 'operationStopReasons') {
            await this.PutResourcev2(this.formObj, async () => {
              await this.FetchTable()
              this.CloseModal()
            })
          } else {
            await this.PostResourcev2(this.formObj, async () => {
              await this.FetchTable()
              this.CloseModal()
            })
          }
        } else return false
      })
    },
    async onDelete(id) {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk() {
          me.fetching = true
          const url = `operationStopReasons/${id}`
          await me.AxiosDelete(url, {}, async () => {
            await me.FetchTable()
            me.fetching = false
          })
          me.fetching = false
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
      ]
      const [workCenters] = await this.GetConcurrencyResources(arr)
      this.workCenters = workCenters
    },
  },
  async mounted() {
    await this.FetchTable()
    await this.fetchCombos()
  },
}
</script>
