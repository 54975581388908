<template>
  <div>
    <div class="air__utils__heading"> <h5>Catálogo operaciones</h5> </div>
    <my-breadcrumbs :routes="routes"/>

    <a-tabs defaultActiveKey="1" type="card" size="large">
      <a-tab-pane key="1">
        <span slot="tab">
          <a-icon type="calendar" /> Tipos de Procesos
        </span>
        <div class="card">
          <div class="card-body text-center">
            <processTypes/>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">
          <a-icon type="calendar" />Tipos de Formulaciones
        </span>
        <div class="card">
          <div class="card-body text-center">
            <formulationTypes/>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3">
        <span slot="tab">
          <a-icon type="calendar" />Áreas de trabajo terminado
        </span>
        <div class="card">
          <div class="card-body text-center">
            <finishArea/>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="4">
        <span slot="tab">
          <a-icon type="calendar" />Razones de paro
        </span>
        <div class="card">
          <div class="card-body text-center">
            <stopReasons/>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="5">
        <span slot="tab">
          <a-icon type="calendar" />Tipos de Manejo de Almacenamiento
        </span>
        <div class="card">
          <div class="card-body text-center">
            <storageManagement/>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import processTypes from './processTypes.vue'
import formulationTypes from './formulationTypes.vue'
import finishArea from './finishArea.vue'
import stopReasons from './stopReasons.vue'
import storageManagement from './storageManagement.vue'
export default {
  name: 'mainCatalogs',
  components: {
    processTypes,
    formulationTypes,
    finishArea,
    stopReasons,
    storageManagement,
  },
  data() {
    return {
    // breadcrumbs
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          aIcon: '',
          name: 'operations',
        },
        {
          breadcrumbName: 'Catálogos Operaciones',
          aIcon: '',
          name: 'operationsCatalogs',
        },
      ],
    }
  },
  methods: {},
  async mounted() {},
}
</script>
